const Constants = class {
    static ApiHeader() {
        return new Headers({
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
        })
    }
    static XMLApiHeader() {
        return new Headers({
            'Content-Type': 'application/xml; charset=utf-8',
            Authorization: 'Bearer ' + localStorage.getItem('msal.idtoken'),
        })
    }

    static DEFAULT_RANGE_INDEX = '-'

    static COLORS = {
        validated: '#00c517',
        inprogress: '#ffab3b',
        error: '#eb0000',
        enable: '#3f51b5',
        disable: '#cacaca',
    }

    static STATUS = {
        VALIDATED: 'Validated',
        INPROGRESS: 'InProgress',
        ERROR: 'Error',
        NOTVALIDATED: 'NotValidated',
    }

    static getDefaultAssigmment() {
        return [
            {
                Name: 'User_sUnit',
                Value: 'SI',
            },
            {
                Name: 'AppPerm_b30XBFULLRANGE',
                Value: 'TRUE',
            },
            {
                Name: 'User_sEcatEnable',
                Value: 'Engineering',
            },
            {
                Name: 'AppPerm_bCARRIER',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bCEE',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bCIAT',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bFULLRANGE',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bPrices',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bExpert',
                Value: 'TRUE',
            },
            {
                Name: 'AppPerm_bAdvancedExpert',
                Value: 'TRUE',
            },
        ]
    }
}
export default Constants
