/* eslint-disable */

import { useEffect, useState, useContext } from 'react'
import {
    UpdateCandidateXML,
    FindAllSolutionsBySize,
    getAllSizeForModel,
} from '../../../../Assets/Interfaces/RulesEngineInterface'
import { saveCalculedCandidates } from '../../../../Assets/Interfaces/CandidatesInterface'
import { GlobalContext } from './../../../../store/index'

const useCandidatesPopup = (props) => {
    const [state] = useContext(GlobalContext)
    const [ProgressPercent, setProgressPercent] = useState(null)
    const [Candidates, setCandidates] = useState([])
    const [LoadingMessage, setLoadingMessage] = useState('[ 1 / 3 ] Initialization...')

    async function GetData() {
        try {
            console.info(`## ${state.user.name} generate candidates`)
            let upCandidate = []

            setLoadingMessage('[ 2 / 3 ] Create Candidate Selection algorithm...')
            const allSize = await getAllSizeForModel(props.Model.RulesValue)
            setProgressPercent(0)
            await UpdateCandidateXML(props.Model)
            let n = 0
            let allCandidate = []

            while (n < allSize.length) {
                const allSolution = await FindAllSolutionsBySize(
                    props.Model.Ruleset,
                    props.Model.RulesValue,
                    allSize[n]
                )
                allCandidate.push(allSolution)
                setProgressPercent((oldProgress) => oldProgress + 100 / allSize.length)
                n++
            }

            allCandidate.forEach((sizeCandidate) =>
                sizeCandidate.forEach((candidate) => {
                    upCandidate.push(candidate)
                })
            )

            setProgressPercent(null)
            setLoadingMessage('[ 3 / 3 ] Saving all Candidates...')
            await saveCalculedCandidates(upCandidate, props.Model.RulesValue, props.Model.RangeIndex, state.user.name)
            setCandidates(upCandidate)
            setLoadingMessage('[ 1 / 3 ] Initialization...')
        } catch (error) {
            setLoadingMessage('[ERROR] Impossible to generate candidates')
        }
    }

    const GetParameterDescription = (PropName) => props.Model.Parameters.find((p) => p.Name === PropName).Description

    useEffect(() => {
        if (props.Model.Ruleset && props.open) GetData()
    }, [props, props.Model, props.Model.Ruleset, props.open])

    return {
        ProgressPercent,
        Candidates,
        LoadingMessage,
        GetParameterDescription,
    }
}

export default useCandidatesPopup
