import React from 'react'
import Modal from '../../Common/Modal'
import styled from 'styled-components'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

const WrapColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100px;
`

const WrapRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const StyledButtonGroup = styled(ButtonGroup)`
    margin-top: 16px;
`

const Input = ({ name, handleChange, checked, label }) => {
    return (
        <WrapRow>
            <input onChange={handleChange} type='checkbox' id={name} name={name} checked={checked} />
            <label htmlFor={name}>{label}</label>
        </WrapRow>
    )
}

const CalculationModal = ({ handleRemove, handleAdd, handleClose, handleChange, selected, open }) => {
    const {
        seerMT,
        seerLT,
        seprMT,
        seprHT,
        scopLTa,
        scopLTw,
        scopLTc,
        scopITa,
        scopITw,
        scopITc,
        scopMTa,
        scopMTw,
        scopMTc,
        scopHTa,
        scopHTw,
        scopHTc,
        scopbLTa,
        scopbLTw,
        scopbLTc,
        scopbITa,
        scopbITw,
        scopbITc,
        scopbMTa,
        scopbMTw,
        scopbMTc,
        scopbHTa,
        scopbHTw,
        scopbHTc,
        iplvSI,
        iplvIP,
    } = selected
    return (
        <Modal open={open} handleClose={handleClose} withButton={false} title='Locked'>
            <WrapRow>
                <WrapColumn>
                    <Input checked={seerLT} label='SEERLT' name='seerLT' handleChange={handleChange} />
                    <Input checked={seerMT} label='SEERMT' name='seerMT' handleChange={handleChange} />
                    <Input checked={seprHT} label='SEPRHT' name='seprHT' handleChange={handleChange} />
                    <Input checked={seprMT} label='SEPRMT' name='seprMT' handleChange={handleChange} />
                    <Input checked={scopLTa} label='SCOPLTa' name='scopLTa' handleChange={handleChange} />
                    <Input checked={scopLTw} label='SCOPLTw' name='scopLTw' handleChange={handleChange} />
                    <Input checked={scopLTc} label='SCOPLTc' name='scopLTc' handleChange={handleChange} />
                    <Input checked={scopITa} label='SCOPITa' name='scopITa' handleChange={handleChange} />
                    <Input checked={scopITw} label='SCOPITw' name='scopITw' handleChange={handleChange} />
                    <Input checked={scopITc} label='SCOPITc' name='scopITc' handleChange={handleChange} />
                    <Input checked={scopMTa} label='SCOPMTa' name='scopMTa' handleChange={handleChange} />
                    <Input checked={scopMTw} label='SCOPMTw' name='scopMTw' handleChange={handleChange} />
                    <Input checked={scopMTc} label='SCOPMTc' name='scopMTc' handleChange={handleChange} />
                    <Input checked={scopHTa} label='SCOPHTa' name='scopHTa' handleChange={handleChange} />
                    <Input checked={scopHTw} label='SCOPHTw' name='scopHTw' handleChange={handleChange} />
                    <Input checked={scopHTc} label='SCOPHTc' name='scopHTc' handleChange={handleChange} />
                    <Input checked={scopbLTa} label='SCOPbLTa' name='scopbLTa' handleChange={handleChange} />
                    <Input checked={scopbLTw} label='SCOPbLTw' name='scopbLTw' handleChange={handleChange} />
                    <Input checked={scopbLTc} label='SCOPbLTc' name='scopbLTc' handleChange={handleChange} />
                    <Input checked={scopbITa} label='SCOPbITa' name='scopbITa' handleChange={handleChange} />
                    <Input checked={scopbITw} label='SCOPbITw' name='scopbITw' handleChange={handleChange} />
                    <Input checked={scopbITc} label='SCOPbITc' name='scopbITc' handleChange={handleChange} />
                    <Input checked={scopbMTa} label='SCOPbMTa' name='scopbMTa' handleChange={handleChange} />
                    <Input checked={scopbMTw} label='SCOPbMTw' name='scopbMTw' handleChange={handleChange} />
                    <Input checked={scopbMTc} label='SCOPbMTc' name='scopbMTc' handleChange={handleChange} />
                    <Input checked={scopbHTa} label='SCOPbHTa' name='scopbHTa' handleChange={handleChange} />
                    <Input checked={scopbHTw} label='SCOPbHTw' name='scopbHTw' handleChange={handleChange} />
                    <Input checked={scopbHTc} label='SCOPbHTc' name='scopbHTc' handleChange={handleChange} />
                    <Input checked={iplvSI} label='IPLVSI' name='iplvSI' handleChange={handleChange} />
                    <Input checked={iplvIP} label='IPLVIP' name='iplvIP' handleChange={handleChange} />
                </WrapColumn>
            </WrapRow>

            <StyledButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
                <Button onClick={handleAdd}>add</Button>
                <Button onClick={handleRemove}>reset</Button>
            </StyledButtonGroup>
        </Modal>
    )
}

export default CalculationModal
