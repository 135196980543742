import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import useCalculationOnDetails from './useCalculation'
import { SuccessIcon, FailIcon, PendingIcon } from '../SvgImages'
import { sortOptionDescriptionBySize } from '../../Assets/Helpers'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import BlockIcon from '@material-ui/icons/Block'
import SettingsIcon from '@material-ui/icons/Settings'
import Chip from '@material-ui/core/Chip'

import CalculationModal from './Modal/Calculation'
import LockedModal from './Modal/Locked'
import FilterModal from './Modal/Filter'
import ErrorModal from './Modal/Error'

// TODO: split useCalculation
const SyledChip = styled.div`
    background: ${({ value }) => (value ? '#f16969' : ' #71ab71')};
    color: white;
    min-width: 50px;
    padding: 5px;
    border-radius: 5px;
`

const StyledBlockIcon = styled(BlockIcon)`
    color: red;
`

const StyledTableContainer = styled(TableContainer)`
    max-height: 67vh;
`

const StyledSettingsIcon = styled(SettingsIcon)`
    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    color: grey;
    font-size: 19px;
    animation: rotating 10s linear infinite;
`

const StyledContainerLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    max-width: calc(100% - 50px);
    position: absolute;
`

const TableCellDisable = styled(TableCell)`
    ${({ disabled }) => disabled && 'background: #3e3e3e; border-right: 1px solid white;'};
`

const StyledTableRow = styled(TableRow)`
    :hover {
        background: aliceblue;
    }
`

const StyledTable = styled(Table)`
    border: none;
    b {
        color: #000f7b;
    }
    .MuiTableCell-head {
        color: #c0c0c0;
    }

    .MuiTableCell-sizeSmall {
        padding: 0 8px;
    }
`

const TableInfo = styled.div`
    &&& {
        padding: 10px;
        position: relative;
        background-color: #fafafa;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .margin-space {
            margin: 0px 10px;
        }
    }
`

const CalcRequestDetails = () => {
    const { productName } = useParams()
    const navigate = useNavigate()

    const {
        candidates,
        fetchPostAllCalculations,
        toggleCheckbox,
        toggleAllCheckbox,
        toggleCheckboxSelection,
        handleChange,
        handleSubmit,
        toggleOptionStrict,
        filter,
        isSelectedCheckbox,
        toggleSelectAll,
        selectAll,
        resetFilter,
        calculationSelected,
        status,
        candidatesSelected,
        saveCandidatesLocked,
        deleteCandidatesLocked,
        checkLockedCandidate,
        loader,
    } = useCalculationOnDetails(productName)

    const [openFilter, setOpenFilter] = useState(false)
    const [openCalcul, setOpenCalcul] = useState(false)
    const [openLocked, setOpenLocked] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [message, setMessage] = useState('')

    const handleOpenError = () => setOpenError(true)
    const handleCloseError = () => setOpenError(false)
    const handleOpenLocked = () => candidatesSelected.length > 0 && setOpenLocked(true)
    const handleCloseLocked = () => setOpenLocked(false)
    const handleOpenFilter = () => setOpenFilter(true)
    const handleCloseFilter = () => setOpenFilter(false)
    const handleSubmitFilter = (e) => {
        handleSubmit(e)
        setOpenFilter(false)
    }
    const handleOpenCalcul = () => candidatesSelected.length > 0 && setOpenCalcul(true)
    const handleCloseCalcul = () => setOpenCalcul(false)
    const handleSubmitCalcul = (e) => {
        fetchPostAllCalculations(e)
        setOpenCalcul(false)
    }

    const handleAddLocked = (e) => {
        saveCandidatesLocked(e)
        setOpenLocked(false)
    }

    const handleDeleteLocked = (e) => {
        deleteCandidatesLocked(e)
        setOpenLocked(false)
    }

    return (
        <div>
            {openFilter && (
                <FilterModal
                    handleChange={handleChange}
                    handleSubmit={handleSubmitFilter}
                    handleClose={handleCloseFilter}
                    handleReset={resetFilter}
                    open={openFilter}
                    filter={filter}
                    toggleOptionStrict={toggleOptionStrict}
                    model={productName}
                />
            )}
            {openCalcul && (
                <CalculationModal
                    handleChange={toggleCheckbox}
                    handleAllChange={toggleAllCheckbox}
                    handleSubmit={handleSubmitCalcul}
                    handleClose={handleCloseCalcul}
                    open={openCalcul}
                    model={productName}
                    selected={calculationSelected}
                />
            )}

            {openLocked && (
                <LockedModal
                    handleChange={toggleCheckbox}
                    handleAdd={handleAddLocked}
                    handleRemove={handleDeleteLocked}
                    handleClose={handleCloseLocked}
                    open={openLocked}
                    selected={calculationSelected}
                />
            )}
            <ErrorModal message={message} handleClose={handleCloseError} open={openError} />
            <div onClick={() => navigate(`/calculation/?product=${productName}`)}>{' < Back'}</div>
            {status && candidates ? (
                <>
                    <TableInfo>
                        <div>
                            <Chip variant='outlined' color='primary' label={`Model: ${productName}`} />
                            <Chip
                                variant='outlined'
                                color='primary'
                                label={`Number of Candidates: ${candidates.length}`}
                            />
                        </div>
                        <div>
                            <Button color='primary' variant='outlined' onClick={handleOpenFilter} disableElevation>
                                Select filter
                            </Button>
                            <Button onClick={handleOpenCalcul} color='primary' variant='outlined' disableElevation>
                                Select calculation
                            </Button>
                            <Button onClick={handleOpenLocked} color='primary' variant='outlined' disableElevation>
                                Select Locked
                            </Button>
                        </div>
                    </TableInfo>
                    {loader && <LinearProgress />}
                    <StyledTableContainer component={Paper}>
                        <StyledTable stickyHeader size='small' aria-label='a dense table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            size='small'
                                            color='primary'
                                            onClick={toggleSelectAll}
                                            checked={selectAll}
                                        />
                                    </TableCell>
                                    {candidates[0]?.Parameters &&
                                        candidates[0]?.Parameters.sort(sortOptionDescriptionBySize).map(
                                            (parameter, index) =>
                                                parameter.Description === 'Size' && (
                                                    <TableCell align='center' key={'table-head-description' + index}>
                                                        {parameter.Description === 'Size' && parameter.Description}
                                                    </TableCell>
                                                )
                                        )}
                                    {candidates[0]?.Parameters.length - 1 > 0 && (
                                        <TableCell align='left' colSpan={candidates[0]?.Parameters.length - 1}>
                                            Option
                                        </TableCell>
                                    )}

                                    {status.length > 0 &&
                                        status.map((el, index) => (
                                            <TableCell key={('table-head-status', index)} align='right'>
                                                <b>{el}</b>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidates.length ? (
                                    candidates.sort(sortOptionDescriptionBySize).map((candidate, index) => (
                                        <StyledTableRow key={'row-' + candidate.id + '-' + index}>
                                            <TableCell>
                                                <div title={candidate.id}>
                                                    <Checkbox
                                                        size='small'
                                                        onChange={(event) =>
                                                            toggleCheckboxSelection(event, candidate.id)
                                                        }
                                                        checked={isSelectedCheckbox(candidate.id)}
                                                        id={`checkbox-candidate-${candidate.id}`}
                                                        name={`checkbox-candidate-${candidate.id}`}
                                                        key={`checkbox-candidate-${candidate.id}`}
                                                        color='primary'
                                                    />
                                                </div>
                                            </TableCell>
                                            {candidate.Parameters.sort(sortOptionDescriptionBySize).map((p, pIndex) => (
                                                <TableCell
                                                    align='center'
                                                    value={p.Values[0].Value !== 'TRUE'}
                                                    key={'cell-option-' + candidates.id + '-' + pIndex}>
                                                    {p.Description === 'Size' ? (
                                                        <>{p.Values[0].Value}</>
                                                    ) : (
                                                        <SyledChip value={p.Values[0].Value !== 'TRUE'}>
                                                            {p.Description}
                                                        </SyledChip>
                                                    )}
                                                </TableCell>
                                            ))}

                                            {status.length > 0 &&
                                                status.map((el, index) => (
                                                    <TableCellDisable
                                                        key={'cell-status-' + candidates.id + '-' + index}
                                                        disabled={
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus &&
                                                            !checkLockedCandidate(candidate, el) &&
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus.startsWith('Unable')
                                                        }
                                                        align='right'>
                                                        {!checkLockedCandidate(candidate, el) &&
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus.startsWith('Pending') && (
                                                                <PendingIcon Color='grey' />
                                                            )}
                                                        {!checkLockedCandidate(candidate, el) &&
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus.startsWith('Progress') && (
                                                                <StyledSettingsIcon />
                                                            )}
                                                        {!checkLockedCandidate(candidate, el) &&
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus.startsWith('Success') && (
                                                                <SuccessIcon Color='green' />
                                                            )}

                                                        {!checkLockedCandidate(candidate, el) &&
                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                (e) => e?.CalcType?.trim() === el
                                                            )?.CalcStatus.startsWith('Error') && (
                                                                <div
                                                                    onClick={() => {
                                                                        setMessage(
                                                                            candidate.Parameters[0].Values[0].Status.find(
                                                                                (e) => e?.CalcType?.trim() === el
                                                                            )?.CalcStatus.replace('Error Error:', '')
                                                                        )
                                                                        handleOpenError()
                                                                    }}>
                                                                    <FailIcon Color='red' />
                                                                </div>
                                                            )}

                                                        {checkLockedCandidate(candidate, el) && <StyledBlockIcon />}
                                                    </TableCellDisable>
                                                ))}
                                        </StyledTableRow>
                                    ))
                                ) : (
                                    <Typography variant='caption' component='div' color='textSecondary'>
                                        No data available for your request. Please check your input and try again.
                                    </Typography>
                                )}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                </>
            ) : (
                <StyledContainerLoading>
                    <CircularProgress disableShrink />
                </StyledContainerLoading>
            )}
            <br />
        </div>
    )
}

export default CalcRequestDetails
