import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import useTableDisplayData from './useTableDisplayData'
import usePagination from './usePagination'
import useFilter from '../Modal/useFilter'
import Loader from '../../../Common/Loader'
import Row from './TableRow'
import TableHeader from './TableHeader'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import ModalFilter from '../Modal/Filter'
import Grid from '@material-ui/core/Grid'

import Stats from './Stats'
import { GetAllCalcTpe } from '../../../../Assets/Interfaces/RulesEngineInterface'

const Container = styled(TableContainer)`
    && {
        box-shadow: none;
        background-color: white;
        border: 1px solid #e0e0e0;
        text-align: -webkit-center;
    }
    table {
        border: unset;
    }

    table tbody th {
        color: #6f6e6ede;
    }

    table thead th,
    table tbody th {
        border-top: 1px solid #e0e0e0;
    }

    table thead th:last-child,
    table tbody th:nth-last-child(-n + 1) {
        border-top: none;
    }
    table tbody th:nth-last-child(1),
    table thead th:nth-last-child(1) {
        border-left: 1px solid #e0e0e0;
        border-top: none;
    }

    .MuiToolbar-root {
    }

    .MuiTableCell-root {
        padding: 4px;
    }

    table thead th {
        color: #000f7b;
        font-size: 11px;
        padding: 5px 8px !important;
        line-height: 1.2;
    }

    table table-detailed thead th,
    && table table-detailed tbody th {
        border: none !important;
    }
`

const TableDetail = styled(Table)`
    & {
        border: none;
    }
`
const StyledTablePagination = styled(TablePagination)`
    &&& {
        color: #828181;
        border-top: 1px solid #e0e0e0;
    }
`

const StyledTableHeader = styled(TableHeader)`
    text-align: -webkit-center;
`

const TableDisplayData = ({ product }) => {
    const location = useLocation()
    const [queryParam] = useState(queryString.parse(location.search))
    const [rows, setRows] = useState([])
    const { data, openTable, toggleTable } = useTableDisplayData(product)
    const { filter, open, sizes, dataFiltred, handleChange, toggleFilter, handleSubmit } = useFilter(data)
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination()
    const ref = useRef(null)
    const [calculationList, setCalculationList] = useState([])

    useEffect(() => {
        const rows = []
        const startIndex = page * rowsPerPage
        const endIndex = startIndex + rowsPerPage
        if (data && data.length > 0) {
            for (let i = startIndex; i < endIndex && i < data.length; i++) {
                rows.push(data[i])
            }
            setRows(rows)
        }
    }, [page, rowsPerPage, data])

    useEffect(() => {
        if (queryParam.product === product) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
        GetAllCalcTpe(product).then((result) => {
            setCalculationList(result.filter(Boolean))
        })
    }, [])

    return (
        product && (
            <Grid item xs={openTable ? 12 : 4}>
                <Container ref={ref}>
                    {open && (
                        <ModalFilter
                            open={open}
                            handleChange={handleChange}
                            handleClose={toggleFilter}
                            handleSubmit={handleSubmit}
                            filter={filter}
                            title={product}
                            list={sizes}
                        />
                    )}
                    <StyledTableHeader
                        onClick={toggleTable}
                        isOpen={openTable}
                        product={product}
                        data={data}
                        calcTypes={calculationList}
                    />
                    {openTable && (
                        <Collapse timeout={500} in={openTable}>
                            {openTable ? <Stats name={product.name} /> : null}
                            {rows.length === 0 && <Loader />}
                            {rows.length > 0 && (
                                <TableDetail>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Option</TableCell>
                                            <TableCell align='center'>Size</TableCell>
                                            <TableCell align='center'>SEER LT</TableCell>
                                            <TableCell align='center'>SEER MT</TableCell>
                                            <TableCell align='center'>SEPR HT</TableCell>
                                            <TableCell align='center'>SEPR MT</TableCell>
                                            <TableCell align='center'>SCOP LTa</TableCell>
                                            <TableCell align='center'>SCOP MTa</TableCell>
                                            <TableCell component='th' align='center' scope='row'>
                                                <IconButton size='small' onClick={toggleFilter} disabled={false}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <Row key={product + '-row-' + index} row={row} />
                                        ))}
                                    </TableBody>
                                </TableDetail>
                            )}
                            <StyledTablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component='div'
                                count={data?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Collapse>
                    )}
                </Container>
            </Grid>
        )
    )
}

export default TableDisplayData
